function Ordering({
  ordering,
  setOrdering,
}: {
  ordering: string;
  setOrdering: (newValue: string) => void;
}) {
  return (
    <div className="row g-3">
      <label htmlFor="ordering" className="col-auto col-form-label">
        Sorted by&nbsp;
      </label>
      <div className="col">
        <select
          className="form-select"
          name="ordering"
          data-testid="ordering"
          value={ordering.valueOf()}
          onChange={(e) => {
            setOrdering(e.target.value);
            e.preventDefault();
          }}
        >
          <option value="highest">Highest Rated</option>
          <option value="youngest">Youngest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>
    </div>
  );
}

export default Ordering;
