import { Result } from "../components/Data";
import Footer from "../components/Footer";
import { errorData, fetchJSON } from "../components/Loaders";
import Logo from "../components/Logo";
import Password from "../components/Password";
import { Login, useUIStore } from "../components/Store";
import { AddToast } from "../components/Toasts";
import { useEffect, useState } from "react";
import { Link, useHref } from "react-router-dom";

const registerAction = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  await fetchJSON<Login>({
    url: "/api/register",
    method: "POST",
    content: {
      email,
      password,
    },
  })
    .then((result) => {
      console.debug("Register result", result);
      AddToast("Successfully registered!");
      useUIStore.setState((s) => ({ ...s, loginData: result.data }));
      return Promise.resolve(result);
    })
    .catch((error) => {
      const data = errorData(error) as Result;
      return Promise.reject(data["message"]);
    });

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const rootURL = useHref("/");
  const [registerFailure, setRegisterFailure] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = "Freshet: Register";
  }, []);

  return (
    <main
      id="register-panel"
      role="main"
      className="container"
      style={{ paddingTop: "5rem" }}
    >
      <div>
        <h1>
          <Logo />
          Freshet
        </h1>
        <p className="lead">
          Register to Freshet (or{" "}
          <Link id="login-link" to="/Login">
            login
          </Link>
          )
        </p>
        <form>
          <div className="form-group">
            <label htmlFor="input-email">Email address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="form-control"
              required={true}
              id="input-email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <Password
              password={password}
              setPassword={setPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              clearFailure={() => setRegisterFailure(null)}
            />
          </div>
          <div
            data-testid="register-failure"
            className="alert alert-danger"
            role="alert"
            style={{ display: registerFailure !== null ? "block" : "none" }}
          >
            Registration failure: {registerFailure}
          </div>
          <button
            id="register-button"
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              registerAction({ email, password })
                .then(() => {
                  window.location.replace(rootURL);
                })
                .catch((res) => {
                  setRegisterFailure(res as string);
                });
              e.preventDefault();
            }}
          >
            Register
          </button>
        </form>
      </div>
      <Footer />
    </main>
  );
}

export default Register;
