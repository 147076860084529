import { AccountEntry, LoadingAccountEntry } from "./Data";
import { DateTime } from "luxon";

export const parseAccountEntry = (item: LoadingAccountEntry): AccountEntry => ({
  ...item,
  entry: {
    ...item.entry,
    publish_date: DateTime.fromISO(item.entry.publish_date),
  },
  loaded_from_backend_at: DateTime.now(),
});

export type SelectFunction<Type> = (accountEntries: Type) => AccountEntry[];

export const parseAccountEntries: SelectFunction<LoadingAccountEntry[]> = (
  items: LoadingAccountEntry[],
): AccountEntry[] => items.map(parseAccountEntry);
