function Footer() {
  return (
    <footer className="text-center">
      <div className="container">
        <div className="row fs-6">
          <span>
            <a href="https://freshet.net/docs/" target="_blank">
              Docs
            </a>{" "}
            /&nbsp;
            <a href="https://freshet.net/faq/" target="_blank">
              FAQ
            </a>{" "}
            /&nbsp;
            <a href="https://freshet.net/posts/" target="_blank">
              Blog
            </a>{" "}
            /&nbsp;
            <a rel="me" href="https://mastodon.social/@freshet" target="_blank">
              <span className="fab fa-mastodon fa-fw"></span>
              @freshet@mastodon.social
            </a>
          </span>
          <br />
          <a href="https://ko-fi.com/freshet" target="_blank">
            Sponsor us at Ko-fi
          </a>
          <br />
          <span>Freshet is a project of Metafrey Ltd</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
