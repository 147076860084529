import { IUIStore, useUIStore } from "./Store";
import { Toast } from "bootstrap";
import { produce } from "immer";
import { useEffect } from "react";

export const Toasts = () => {
  const toasts = useUIStore((s) => s.toasts);
  function toastId(t: string): string {
    return `toast-${t.toLowerCase().replaceAll(new RegExp("[^a-z]", "g"), "")}`;
  }
  function removeToast(toast: string) {
    useUIStore.setState(
      produce((s: IUIStore) => {
        s.toasts = s.toasts.filter((t) => t !== toast);
      }),
    );
  }
  useEffect(() => {
    toasts.forEach((t) => {
      const toastElement = document.getElementById(toastId(t));
      if (toastElement !== null) {
        const toastBootstrap = Toast.getOrCreateInstance(toastElement);
        toastBootstrap.show();
        toastElement.addEventListener("hidden.bs.toast", () => {
          removeToast(t);
        });
      }
    });
  }, [toasts]);
  return (
    <div className="toast-container position-fixed top-0 end-0 p-3">
      {toasts.map((toast) => (
        <div
          id={toastId(toast)}
          key={toastId(toast)}
          data-testid={toastId(toast)}
          className="toast align-items-center"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{toast}</div>
            <button
              id={`${toastId(toast)}-close`}
              data-testid={`${toastId(toast)}-close`}
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      ))}
    </div>
  );
};

export const AddToast = (toast: unknown) => {
  useUIStore.setState(
    produce((s: IUIStore) => {
      if (typeof toast !== "string") {
        console.warn("Bad toast", toast);
        return;
      }
      if (!s.toasts.includes(toast)) {
        s.toasts.push(toast);
      }
    }),
  );
};
