import { Feed, Result } from "../components/Data";
import {
  errorParser,
  fetchJSON,
  hasFeedsTest,
  hasWorkingFeedsTest,
  isNullOrUndefined,
  LOAD_FEEDS_KEY,
  useFeeds,
} from "../components/Loaders";
import { useUIStore } from "../components/Store";
import { AddToast } from "../components/Toasts";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Feeds() {
  const [url, setUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();
  const sessionId = useUIStore((s) => s.sessionId);
  const feeds = useFeeds(sessionId);
  const hasFeeds = hasFeedsTest(feeds);
  const hasWorkingFeeds = hasWorkingFeedsTest(feeds);

  useEffect(() => {
    document.title = "Freshet: Feeds";
  }, []);

  const postFeed = (result: Promise<Result>) =>
    result
      .then((res) => {
        setSuccess(true);
        AddToast(res.message);
        void queryClient.invalidateQueries({ queryKey: LOAD_FEEDS_KEY });
      })
      .catch((reason) => {
        setSuccess(false);
        setMessage(reason as string);
      });

  const createFeed = async (): Promise<void> => {
    if (url === "") {
      setSuccess(false);
      setMessage("Blank url!");
      return Promise.resolve();
    }
    return await fetchJSON<Result>({
      url: "/api/feed",
      method: "POST",
      content: { url },
    })
      .then((result) => {
        setUrl("");
        return postFeed(Promise.resolve(result.data));
      })
      .catch((err) =>
        postFeed(Promise.reject(errorParser("create feed", err))),
      );
  };

  const deleteFeed = async (feed_id: string) =>
    await fetchJSON<Result>({
      url: `/api/feed/${feed_id}`,
      method: "DELETE",
    })
      .then((result) => postFeed(Promise.resolve(result.data)))
      .catch((err) =>
        postFeed(Promise.reject(errorParser("delete feed", err))),
      );

  function ShowFeed({ feed }: { feed: Feed }) {
    if (feed.last_update !== null) {
      if (!isNullOrUndefined(feed.title)) {
        return (
          <span key={`${feed.id}-title`} data-testid={`${feed.id}-title`}>
            {feed.site_url === null ? (
              feed.title
            ) : (
              <a href={feed.site_url} target="_blank">
                {feed.title}
              </a>
            )}
          </span>
        );
      } else if (isNullOrUndefined(feed.last_success)) {
        return (
          <span key={`${feed.id}-failed`} data-testid={`${feed.id}-failed`}>
            Failed to load{" "}
            <a href={feed.url} target="_blank">
              {feed.url}
            </a>{" "}
            since {feed.last_update.toRelative()}
            {feed.failure_reason === null ? "" : `: ${feed.failure_reason}`}
          </span>
        );
      }
    }
    return (
      <span key={`${feed.id}-loading`} data-testid={`${feed.id}-loading`}>
        Loading {feed.url}
      </span>
    );
  }

  const feedSortKey = (feed: Feed): string => {
    if (feed.title !== null) {
      return feed.title;
    } else {
      return feed.url;
    }
  };

  return (
    <>
      {!hasFeeds && (
        <>
          <span className="fs-5" data-testid="no-feeds-msg">
            You haven&apos;t setup any feeds yet. Please add one or more feeds
            below. If you&apos;re not sure what to add, try a URL of a news site
            you like. See{" "}
            <a
              href="https://freshet.net/docs/#adding-your-first-feed"
              target="_blank"
            >
              our docs for more details
            </a>
            .
          </span>
          <hr />
        </>
      )}
      {hasFeeds && !hasWorkingFeeds && (
        <>
          <span className="fs-5" data-testid="no-working-feeds-msg">
            You have at least one feed, but we need at least one working one.
          </span>
          <hr />
        </>
      )}
      <div id="feeds-tab" className="row g-3">
        <label htmlFor="input-url" className="col-auto col-form-label">
          Feed URL
        </label>
        <input
          type="url"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
            setMessage("");
          }}
          className="form-control col mb-2"
          required={true}
          data-testid="input-url"
          id="input-url"
          placeholder="Enter feed URL"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              void createFeed();
              e.preventDefault();
            }
          }}
        />{" "}
        <div
          id="message"
          className={"alert " + (success ? "alert-success" : "alert-danger")}
          role="alert"
          data-testid="message"
          style={{ display: message === "" ? "none" : "block" }}
        >
          {message}
          &nbsp;
          <button
            type="button"
            className="btn btn-secondary close"
            aria-label="Close"
            data-testid="close"
            onClick={(e) => {
              setMessage("");
              e.preventDefault();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        {/* eslint-disable-next-line idiomatic-jsx-u/require-attributes */}
        <a
          id="add-feed"
          className="btn btn-primary feed-action col-auto mb-2"
          role="button"
          href="#"
          data-testid="add-feed"
          onClick={(e) => {
            void createFeed();
            e.preventDefault();
          }}
        >
          Add Feed
        </a>
      </div>

      {/* eslint-disable-next-line idiomatic-jsx-u/require-attributes */}
      <a
        className="btn btn-secondary feed-action"
        role="button"
        href="#"
        data-testid="load-feeds"
        onClick={(e) => {
          void queryClient.invalidateQueries({ queryKey: LOAD_FEEDS_KEY });
          e.preventDefault();
        }}
      >
        Update feed list
      </a>
      <hr />
      {feeds.isSuccess && (feeds.data || []).length > 0 && (
        <table className="table table-hover table-borderless table-sm">
          <tbody>
            {(feeds.data || [])
              .sort((a, b) => feedSortKey(a).localeCompare(feedSortKey(b)))
              .map((feed) => (
                <tr key={`${feed.id}-top`}>
                  <td>
                    <ShowFeed feed={feed} />
                  </td>
                  <td>
                    {!isNullOrUndefined(feed.last_success) && (
                      <>
                        <Link
                          className="btn btn-primary btn-sm"
                          data-testid={`${feed.id}-select`}
                          to={`/Feed/${feed.id}`}
                        >
                          Show unread items
                        </Link>{" "}
                      </>
                    )}
                    <button
                      key={`${feed.id}-delete`}
                      className="btn btn-sm btn-secondary delete-feed"
                      id={`${feed.id}-delete`}
                      data-testid={`${feed.id}-delete`}
                      onClick={(e) => {
                        void deleteFeed(feed.id);
                        e.preventDefault();
                      }}
                    >
                      Unsubscribe
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {feeds.isSuccess && (feeds.data || []).length === 0 && (
        <span data-testid="no-feeds">No feeds</span>
      )}
    </>
  );
}

export default Feeds;
