import { Result } from "../components/Data";
import { errorData, errorParser, fetchJSON } from "../components/Loaders";
import { useUIStore } from "../components/Store";
import { AddToast } from "../components/Toasts";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface UserInfo {
  max_active_users: number;
  active_users: number;
  queued_users: number;
}

const LOAD_USER_INFO_KEY = ["user_info"];
const loadUserInfo = (sessionId: string) => {
  const internal = async (): Promise<UserInfo> =>
    await fetchJSON<UserInfo>({ sessionId, url: "/api/users" })
      .then((result) => result.data)
      .catch((error) => Promise.reject(errorParser("user info", error)));
  return internal;
};

const setMaxActiveUsers = async ({
  queryClient,
  maxActiveUsers,
}: {
  sessionId: string;
  queryClient: QueryClient;
  maxActiveUsers: number;
}) =>
  await fetchJSON<Result>({
    url: "/api/set_max_active_users",
    method: "PUT",
    content: {
      max_active_users: maxActiveUsers,
    },
  })
    .then((result) => {
      console.debug("Set max active users", result.data);
      return queryClient
        .invalidateQueries({ queryKey: LOAD_USER_INFO_KEY })
        .then(() => Promise.resolve(result.data));
    })
    .catch((error) => {
      const data = errorData(error);
      return Promise.reject(data);
    });

function Users() {
  const queryClient = useQueryClient();
  const sessionId = useUIStore((s) => s.sessionId);
  const usersData = useQuery({
    queryKey: LOAD_USER_INFO_KEY,
    queryFn: loadUserInfo(sessionId),
  });
  const [maxActiveUsers, localMaxActiveUsers] = useState<number | null>(null);

  useEffect(() => {
    if (usersData.isSuccess) {
      localMaxActiveUsers(usersData.data.max_active_users);
    }
  }, [usersData.data?.max_active_users, usersData.isSuccess]);

  useEffect(() => {
    document.title = "Freshet: Users";
  }, []);

  if (!usersData.isSuccess || maxActiveUsers === null) {
    return <div>Loading user info...</div>;
  }

  const userInfo = usersData.data;
  return (
    <div id="users-tab">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Max active users</td>
            <td data-testid="max-active-users">{userInfo.max_active_users}</td>
          </tr>
          <tr>
            <td>Active users</td>
            <td data-testid="active-users">{userInfo.active_users}</td>
          </tr>
          <tr>
            <td>Queued users</td>
            <td data-testid="queued-users">{userInfo.queued_users}</td>
          </tr>
        </tbody>
      </table>
      <div className="form-group">
        <label htmlFor="input-max-users">Max active users</label>
        <input
          type="number"
          data-testid="max-active-users-input"
          value={maxActiveUsers}
          onChange={(e) => {
            localMaxActiveUsers(parseInt(e.target.value));
          }}
          className="form-control"
          required={true}
          id="input-email"
          aria-describedby="emailHelp"
          placeholder="Enter max users count"
        />
      </div>
      <button
        id="set-max-active-users"
        type="submit"
        className="btn btn-primary"
        data-testid="set-max-active-users"
        onClick={(e) => {
          setMaxActiveUsers({ sessionId, queryClient, maxActiveUsers })
            .then((res) => {
              AddToast(res["message"]);
            })
            .catch((res) => {
              AddToast((res as Result)["message"]);
            });
          e.preventDefault();
        }}
      >
        Set max active users
      </button>
    </div>
  );
}

export default Users;
