import { LOAD_CONFIG_KEY, loadConfig } from "./Loaders";
import Logo from "./Logo";
import { useWebsocket } from "./Websocket";
import { useUIStore } from "../components/Store";
import ForgotPassword from "../panels/ForgotPassword";
import Home from "../panels/Home";
import Login from "../panels/Login";
import PasswordReset from "../panels/PasswordReset";
import Register from "../panels/Register";
import { History, RecentEntries, TopEntries } from "../tabs/Entries";
import Feed from "../tabs/Feed";
import Feeds from "../tabs/Feeds";
import { Search } from "../tabs/Search";
import Tag from "../tabs/Tag";
import Tags from "../tabs/Tags";
import Users from "../tabs/Users";
import { useQuery } from "@tanstack/react-query";
import {
  createHashRouter,
  redirect,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

function ErrorPage() {
  const error = useRouteError() as Response;

  return (
    <div id="error-page" data-testid="error-page">
      <h1>
        <Logo />
        Oops!
      </h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText}</i>
      </p>
    </div>
  );
}

function App() {
  const store = useUIStore.getState();
  console.debug("app store", JSON.stringify(store));
  const sessionId = store.sessionId;
  const config = useQuery({
    queryKey: LOAD_CONFIG_KEY,
    queryFn: loadConfig(sessionId),
  });
  useWebsocket();
  const router = createHashRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
      loader: ({ request }) => {
        if (config.isError) {
          throw new Response("Config Error", { status: 500 });
        }
        const store = useUIStore.getState();
        const accountId = store.loginData?.id;
        const loggedIn = accountId !== undefined;
        if (!loggedIn) {
          const url = new URL(request.url);
          console.debug(
            "redirect for login",
            loggedIn,
            url.pathname,
            url.search,
          );
          return redirect(
            `/login?redirect=${encodeURIComponent(url.pathname + url.search)}`,
          );
        }
        return null;
      },
      children: [
        {
          path: "best",
          element: <TopEntries sessionId={sessionId} />,
        },
        {
          path: "recent",
          element: <RecentEntries sessionId={sessionId} />,
        },
        {
          path: "feeds",
          element: <Feeds />,
        },
        {
          path: "feed/:feedId",
          element: <Feed />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "history",
          element: <History sessionId={sessionId} />,
        },
        {
          path: "tags",
          element: <Tags />,
        },
        {
          path: "tag/:tagId",
          element: <Tag />,
        },
        {
          path: "search",
          element: <Search sessionId={sessionId} />,
        },
        {
          // Dummy entry, gets dealt with by Home.tsx
          path: "registration",
          element: <span />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "Register",
      element: <Register />,
      errorElement: <ErrorPage />,
    },
    {
      path: "ForgotPassword",
      element: <ForgotPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "PasswordReset",
      element: <PasswordReset />,
      errorElement: <ErrorPage />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
