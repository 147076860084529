import { Entries } from "./Entries";
import { LOAD_SEARCH_KEY, loadSearch } from "../components/Loaders";
import { useEffect, useState } from "react";

export const Search = ({ sessionId }: { sessionId: string }) => {
  const [searchTerms, setSearchTerms] = useState("");
  const searchNoUnreadElement = <>No results for &quot;{searchTerms}&quot;</>;
  const loader = () => loadSearch(sessionId)(searchTerms);
  const runningSearch = searchTerms.length >= 3;
  useEffect(() => {
    if (!runningSearch) {
      document.title =
        searchTerms === ""
          ? "Freshet: Search"
          : `Freshet: Search - ${searchTerms}`;
    }
  }, [runningSearch, searchTerms]);
  return (
    <>
      <div id="search-choices" className="row g-3">
        <label htmlFor="input-url" className="col-auto col-form-label">
          Enter search terms
        </label>
        <input
          type="text"
          value={searchTerms}
          onChange={(e) => {
            setSearchTerms(e.target.value);
          }}
          className="form-control col mb-2"
          required={true}
          data-testid="search-terms"
          id="search-terms"
          placeholder="Enter search terms (at least 3 characters)"
        />
      </div>
      {runningSearch && (
        <Entries
          name={`Search - ${searchTerms}`}
          loadkey={LOAD_SEARCH_KEY.concat([searchTerms])}
          loader={loader}
          entriesKey={`search-${searchTerms}`}
          noUnreadElement={searchNoUnreadElement}
        />
      )}
    </>
  );
};
