import Footer from "../components/Footer";
import { errorParser, fetchJSON } from "../components/Loaders";
import Logo from "../components/Logo";
import Password from "../components/Password";
import { Login as LoginData, useUIStore } from "../components/Store";
import { useEffect, useState } from "react";
import { Link, useHref, useNavigate, useSearchParams } from "react-router-dom";

const loginAction = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  await fetchJSON<LoginData>({
    url: "/api/login",
    method: "POST",
    content: { email, password },
  })
    .then((result) => {
      console.debug("Login result", result.data);
      useUIStore.setState((s) => ({ ...s, loginData: result.data }));
      return Promise.resolve(result);
    })
    .catch((error) => Promise.reject(errorParser("login", error)));

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams, _] = useSearchParams();
  const redirectPath = useHref(searchParams.get("redirect") ?? "/");
  const navigate = useNavigate();
  const [loginFailure, setLoginFailure] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = "Freshet: Login";
  }, []);

  return (
    <main
      id="login-panel"
      role="main"
      className="container"
      style={{ paddingTop: "5rem" }}
      data-testid="login-panel"
    >
      <div>
        <h1>
          <Logo />
          Freshet
        </h1>
        <p className="lead">
          Login to Freshet (or{" "}
          <Link id="register-link" to="/Register">
            register
          </Link>
          )
        </p>
        <form>
          <div className="form-group">
            <label htmlFor="input-email">Email address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setLoginFailure(false);
                setEmail(e.target.value);
              }}
              className="form-control"
              required={true}
              id="input-email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <Password
              password={password}
              setPassword={setPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              clearFailure={() => setLoginFailure(false)}
            />
            <Link id="forgot-password-link" to="/ForgotPassword">
              Forgot password?
            </Link>
          </div>
          <br />
          <div
            data-testid="login-failure"
            className="alert alert-danger"
            role="alert"
            style={{ display: loginFailure ? "block" : "none" }}
          >
            Login failure. Do you have the correct email or password?
          </div>
          <button
            id="login-button"
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              void loginAction({ email, password })
                .then(() => {
                  console.debug("login navigate", redirectPath);
                  navigate(redirectPath, { replace: true, relative: "route" });
                })
                .catch((err) => {
                  console.warn("login failure", err);
                  setLoginFailure(true);
                });
              e.preventDefault();
            }}
          >
            Login
          </button>
        </form>
      </div>
      <Footer />
    </main>
  );
}

export default Login;
