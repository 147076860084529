import { Result } from "../components/Data";
import Footer from "../components/Footer";
import { errorData, errorParser, fetchJSON } from "../components/Loaders";
import Logo from "../components/Logo";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const forgotPasswordAction = async ({ email }: { email: string }) =>
  await fetchJSON<Result>({
    url: "/api/create_password_reset",
    method: "POST",
    content: { email },
  })
    .then((result) => Promise.resolve(result.data))
    .catch((error) => {
      const data = errorData(error) as Result;
      console.warn(errorParser("forgot password", error));
      return Promise.reject(data);
    });

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [forgotPasswordFailure, setForgotPasswordFailure] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "Freshet: Forgot Password";
  }, []);

  return (
    <main
      id="forgot-password-panel"
      role="main"
      className="container"
      style={{ paddingTop: "5rem" }}
      data-testid="forgot-password-panel"
    >
      <div>
        <h1>
          <Logo />
          Freshet
        </h1>
        <p className="lead">
          Get password reset email (or{" "}
          <Link id="login-link" to="/Login">
            return to login
          </Link>
          )
        </p>
        <form>
          <div className="form-group">
            <label htmlFor="input-email">Email address</label>
            <input
              type="email"
              data-testid="email"
              value={email}
              onChange={(e) => {
                setForgotPasswordFailure(false);
                setMessage("");
                setEmail(e.target.value);
              }}
              className="form-control"
              required={true}
              id="input-email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <br />
          <div
            id="forgot-password-message"
            data-testid="forgot-password-message"
            className={
              forgotPasswordFailure
                ? "alert alert-danger"
                : "alert alert-success"
            }
            role="alert"
            style={{ display: message !== "" ? "block" : "none" }}
          >
            {forgotPasswordFailure
              ? "Failure sending forgot password email: "
              : ""}
            {""}
            {message}
          </div>
          <button
            id="send-password-button"
            data-testid="send-password-button"
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              if (email.length > 0) {
                forgotPasswordAction({ email })
                  .then((r) => {
                    setForgotPasswordFailure(false);
                    setMessage(r["message"]);
                  })
                  .catch((r: Result) => {
                    setForgotPasswordFailure(true);
                    setMessage(r["message"]);
                  });
              }
              e.preventDefault();
            }}
          >
            Send password email
          </button>
        </form>
      </div>
      <Footer />
    </main>
  );
}

export default ForgotPassword;
