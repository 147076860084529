import { Result } from "../components/Data";
import Footer from "../components/Footer";
import { errorData, fetchJSON } from "../components/Loaders";
import Logo from "../components/Logo";
import Password from "../components/Password";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

const setPasswordAction = async ({
  email,
  newPassword,
  token,
}: {
  email: string;
  newPassword: string;
  token: string;
}) =>
  await fetchJSON<Result>({
    url: "/api/password_reset",
    method: "POST",
    content: { email, token, new_password: newPassword },
  })
    .then((result) => result.data)
    .catch((err) => Promise.reject(errorData(err)));

function PasswordReset() {
  const [searchParams, _] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [passwordResetFailure, setPasswordResetFailure] = useState<
    boolean | null
  >(null);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = "Freshet: Password reset";
  }, []);

  if (email === null) {
    return (
      <span data-testid="missing-email">
        No email set!{" "}
        <Link id="home-link" to="/">
          Return to home
        </Link>
      </span>
    );
  }

  if (token === null) {
    return (
      <span data-testid="missing-token">
        No token set!{" "}
        <Link id="home-link" to="/">
          Return to home
        </Link>
      </span>
    );
  }

  return (
    <main
      id="password-reset-panel"
      role="main"
      className="container"
      style={{ paddingTop: "5rem" }}
      data-testid="forgot-password-panel"
    >
      <div>
        <h1>
          <Logo />
          Freshet
        </h1>
        <p className="lead">Enter new password</p>
        <form>
          <div className="form-group">
            <label htmlFor="input-email">Email address</label>
            <input
              type="email"
              data-testid="email"
              value={email}
              className="form-control"
              disabled={true}
              id="input-email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <Password
              password={newPassword}
              setPassword={setNewPassword}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              clearFailure={() => setPasswordResetFailure(null)}
            />
          </div>
          <br />
          <div
            id="reset-password-message"
            data-testid="reset-password-message"
            className={
              passwordResetFailure
                ? "alert alert-danger"
                : "alert alert-success"
            }
            role="alert"
            style={{ display: message !== "" ? "block" : "none" }}
          >
            {passwordResetFailure === true
              ? "Failure resetting password: "
              : ""}
            {""}
            {message}
            {passwordResetFailure === false && (
              <>
                <br />
                <Link id="login-link" to="/Login">
                  Login with your new password
                </Link>
              </>
            )}
          </div>
          <button
            id="set-password-button"
            data-testid="set-password-button"
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              if (newPassword.length > 0) {
                setPasswordAction({ email, newPassword, token })
                  .then((r) => {
                    setPasswordResetFailure(false);
                    setMessage(r["message"]);
                  })
                  .catch((r: Result) => {
                    setPasswordResetFailure(true);
                    setMessage(r["message"]);
                  });
              }
              e.preventDefault();
            }}
          >
            Reset password
          </button>
        </form>
      </div>
      <Footer />
    </main>
  );
}

export default PasswordReset;
