import { WorkingEntries } from "./Entries";
import Ordering from "./Ordering";
import { LoadingAccountEntry } from "../components/Data";
import { errorParser, fetchJSON, useFeeds } from "../components/Loaders";
import { parseAccountEntries } from "../components/Parsers";
import { useUIStore } from "../components/Store";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

interface unreadFeedArgs {
  sessionId: string;
  feed_id: string;
  ordering: string;
}

export const loadUnreadEntries = async (
  args: unreadFeedArgs,
): Promise<LoadingAccountEntry[]> =>
  await fetchJSON<LoadingAccountEntry[]>({
    sessionId: args.sessionId,
    url: `/api/feed/${args.feed_id}/unread_entries?ordering=${args.ordering}`,
  })
    .then((result) => Promise.resolve(result.data))
    .catch((err) =>
      Promise.reject(errorParser(`unread entries for ${args.feed_id}`, err)),
    );

function Feed() {
  const sessionId = useUIStore((s) => s.sessionId);
  const feeds = useFeeds(sessionId);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const feedId = useParams().feedId!;
  const feed = (feeds.data ?? []).find((f) => f.id === feedId);
  const [ordering, setOrdering] = useState("highest");
  const unreadsKey = ["load-unreads", feedId, ordering];

  useEffect(() => {
    document.title = `Freshet: Feed - ${feed?.title || ""}`;
  }, [feed?.title]);

  return (
    <span>
      <Link to="/Feeds">Return to full feeds list</Link>
      <br />
      <h4>Unread entries for {feed?.title}</h4>
      <Ordering ordering={ordering} setOrdering={setOrdering} />
      <br />
      <WorkingEntries
        loadkey={unreadsKey}
        loader={() =>
          loadUnreadEntries({
            sessionId,
            feed_id: feedId,
            ordering: ordering.valueOf(),
          })
        }
        selectFunction={parseAccountEntries}
        noUnreadElement={<>You&apos;ve read everything on this feed.</>}
        entriesKey={`feed-${feedId}-${ordering.valueOf()}`}
      />
    </span>
  );
}

export default Feed;
