import { DateTime } from "luxon";

export interface Result {
  message: string;
}

export interface Feed {
  id: string;
  url: string;
  title: string | null;
  site_url: string | null;
  last_success: DateTime | null;
  last_update: DateTime | null;
  failure_reason: string | null;
}

export type LoadingFeed = Omit<Feed, "last_update" | "last_success"> & {
  last_update: string | null;
  last_success: string | null;
};

export interface Entry {
  id: string;
  url: string;
  title: string;
  publish_date: DateTime;
}

export type LoadingEntry = Omit<Entry, "publish_date"> & {
  publish_date: string;
};

export interface ScoredTag {
  score: number | null;
  positive: number;
  negative: number;
  display: string | null;
  id: string;
}

export type MoreLikeThis = "MORE" | "MEH" | "LESS";

export interface AccountEntry {
  id: string;
  feeds: string[];
  score: number;
  more_like_this: MoreLikeThis | null;
  entry: Entry;
  tags: { [key: string]: ScoredTag };
  loaded_from_backend_at: DateTime;
  has_been_clicked: boolean;
}

export type LoadingAccountEntry = Omit<
  AccountEntry,
  "entry" | "loaded_from_backend_at"
> & {
  entry: LoadingEntry;
};

export interface Config {
  websocket: string;
}

export interface Tag {
  id: string;
  name: string;
}

export enum AccountState {
  ACTIVE = "ACTIVE",
  VERIFIED = "VERIFIED",
  REGISTERED = "REGISTERED",
}

export interface Account {
  id: string;
  email: string;
  groups: string[];
  state: AccountState;
}
