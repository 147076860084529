function Password({
  password,
  setPassword,
  showPassword,
  setShowPassword,
  clearFailure,
}: {
  password: string;
  setPassword: (password: string) => void;
  showPassword: boolean;
  setShowPassword: (value: boolean) => void;
  clearFailure: () => void;
}) {
  return (
    <>
      <label htmlFor="input-password">Password</label>
      <div className="input-group">
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => {
            clearFailure();
            setPassword(e.target.value);
          }}
          className="form-control"
          id="input-password"
          data-testid="input-password"
          placeholder="Password"
        />
        <div className="input-group-append">
          <button
            data-testid="icon-button"
            className="btn btn-outline-secondary"
            type="button"
            onClick={(e) => {
              setShowPassword(!showPassword);
              e.preventDefault();
            }}
          >
            <i
              data-testid="password-icon"
              className={"fa " + (showPassword ? "fa-eye" : "fa-eye-slash")}
              aria-hidden="false"
            ></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default Password;
