import { WorkingEntries } from "./Entries";
import Ordering from "./Ordering";
import { LoadingAccountEntry } from "../components/Data";
import { errorParser, fetchJSON } from "../components/Loaders";
import { parseAccountEntries } from "../components/Parsers";
import { IUIStore, useUIStore } from "../components/Store";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

interface TagResponse {
  tag: string;
  entries: LoadingAccountEntry[];
}

const loadByTag = async (
  sessionId: string,
  tagId: string,
  ordering: string,
): Promise<TagResponse> =>
  fetchJSON<TagResponse>({
    sessionId,
    url: `/api/tag/${tagId}?ordering=${ordering}`,
  })
    .then((result) => Promise.resolve(result.data))
    .catch((err) =>
      Promise.reject(errorParser(`unread entries for ${tagId}`, err)),
    );

function Tag() {
  const [searchParams, _] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let tagId = useParams().tagId!;
  if (tagId.includes("-")) {
    // second half is name
    tagId = tagId.split("-")[0];
  }
  const sessionId = useUIStore((s) => s.sessionId);
  const [ordering, setOrdering] = useState("highest");
  const tagKey = ["tag", tagId, ordering];
  const tagName = useUIStore(
    (s) => s.tagNames[tagId] || searchParams.get("name") || "loading...",
  );

  useEffect(() => {
    document.title = `Freshet: Tag - ${tagName}`;
  }, [tagName]);

  return (
    <div id="tag-tab" className="col" data-testid="tag-tab">
      <Link to="/Tags">Return to top tags list</Link>
      <br />
      <h4>
        Unread entries for <span data-testid="tag-display">{tagName}</span>
      </h4>
      <Ordering ordering={ordering} setOrdering={setOrdering} />
      <WorkingEntries
        loadkey={tagKey}
        loader={() => loadByTag(sessionId, tagId, ordering)}
        selectFunction={(tagResponse: TagResponse) => {
          const entries = parseAccountEntries(tagResponse.entries);
          useUIStore.setState(
            produce((s: IUIStore) => {
              s.tagNames[tagId] = tagResponse.tag;
            }),
          );
          return entries;
        }}
        entriesKey={`tags-${tagId}`}
        noUnreadElement={<>No remaining unread items on this tag</>}
      />
    </div>
  );
}

export default Tag;
